import { type FC } from 'react';
import { Link } from 'react-router-dom';

const PublicFooter: FC = () => (
  <footer className="bg-white dark:bg-gray-800">
    <div className="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
      <div className="grid grid-cols-2 gap-8 md:grid-cols-4 lg:grid-cols-4">
        <div className="text-center">
          <ul className="text-gray-500 dark:text-gray-400">
            <li className="mb-4">
              <Link to="/">トップ</Link>
            </li>
          </ul>
        </div>
        <div className="text-center">
          <ul className="text-gray-500 dark:text-gray-400">
            <li className="mb-4">
              <Link to="/company">会社概要</Link>
            </li>
          </ul>
        </div>
        <div className="text-center">
          <ul className="text-gray-500 dark:text-gray-400">
            <li className="mb-4">
              <Link to="/privacy">プライバシーポリシー</Link>
            </li>
          </ul>
        </div>
        <div className="text-center">
          <ul className="text-gray-500 dark:text-gray-400">
            <li className="mb-4">
              <Link to="/terms">利用規約</Link>
            </li>
          </ul>
        </div>
      </div>
      <hr className="my-4 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-4" />
      <div className="text-center">
        <span className="block text-sm text-center text-gray-500 dark:text-gray-400">
          © 2021-2022
          <a href="https://github.com/themesberg/landwind">Landwind™</a>. All
          Rights Reserved. Built with{' '}
          <a
            href="https://flowbite.com"
            className="text-purple-600 hover:underline dark:text-purple-500"
          >
            Flowbite
          </a>{' '}
          and{' '}
          <a
            href="https://tailwindcss.com"
            className="text-purple-600 hover:underline dark:text-purple-500"
          >
            Tailwind CSS
          </a>
          .
        </span>
      </div>
    </div>
  </footer>
);

export default PublicFooter;
